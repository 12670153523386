<template>
  <div class="app" id="app">
    <h1 v-if="!filteredData.length">Les Vraies Richesses - Rapports</h1>

    <!-- File upload button, hidden when report data is displayed -->
    <input v-if="!filteredData.length" type="file" @change="handleFileUpload" accept=".csv" />

    <!-- Import New Report and Print buttons, visible only when report data is displayed -->
    <div v-if="filteredData.length" class="button-group">
      <button @click="refreshPage">Importer un nouveau rapport</button>
      <button @click="printPage">Imprimer</button>
    </div>

    <!-- Table to display filtered CSV data -->
    <div v-if="filteredData.length">
      <table class="filtered-table">
        <thead>
        <tr>
          <th v-for="column in selectedColumns" :key="column">
            {{ column === 'Name' ? 'Numéro de commande' :
              column === 'Shipping Method' ? 'Endroit de ramassage' :
                  column === 'Lineitem quantity' ? 'Quantité' :
                      column === 'Lineitem name' ? 'Article' :
                          column === 'Billing Name' ? 'Nom du client' :
                                column === 'Billing Phone' ? 'Téléphone' :
                                    column === 'Note Attributes' ? 'Date de ramassage' : column }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, rowIndex) in filteredData" :key="rowIndex">
          <td
              v-for="column in selectedColumns"
              :key="column"
              :class="{ nowrap: column === 'Name' || column === 'Billing Name' }"
          >
            {{ row[column] }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";

export default {
  data() {
    return {
      csvData: [],
      selectedColumns: ["Name", "Shipping Method", "Lineitem quantity", "Lineitem name", "Billing Name", "Note Attributes", "Billing Phone"],
    };
  },
  computed: {
    filteredData() {
      return this.csvData.map((row) =>
          this.selectedColumns.reduce((obj, col) => {
            if (col in row) {
              obj[col] = row[col];
            }
            return obj;
          }, {})
      );
    },
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: this.handleParseComplete,
        });
      }
    },
    handleParseComplete(results) {
      this.csvData = results.data.map((row) => {
        if (row["Note Attributes"]) {
          const match = row["Note Attributes"].match(/Pickup-Date:\s*(\d{1,2}(?:\/\d{2}\/\d{4}|\s+[a-zA-Zéû]+))/);
          row["Note Attributes"] = match ? this.normalizeDate(match[1].trim()) : ""; // Extract and normalize date
        }
        return row;
      });
    },
    normalizeDate(date) {
      const months = {
        janvier: "01",
        février: "02",
        mars: "03",
        avril: "04",
        mai: "05",
        juin: "06",
        juillet: "07",
        août: "08",
        septembre: "09",
        octobre: "10",
        novembre: "11",
        décembre: "12",
      };

      // Handle DD/MM/YYYY format
      if (/^\d{1,2}\/\d{2}\/\d{4}$/.test(date)) {
        return date;
      }

      // Handle DD MonthName format
      const match = date.match(/^(\d{1,2})\s+([a-zA-Zéû]+)$/);
      if (match) {
        const day = match[1].padStart(2, "0"); // Ensure day is two digits
        const month = months[match[2].toLowerCase()]; // Convert month name to number
        const year = new Date().getFullYear(); // Use the current year
        return month ? `${day}/${month}/${year}` : date;
      }

      // Return original if no match
      return date;
    },
    refreshPage() {
      // Reload the page to reset the report
      window.location.reload();
    },
    printPage() {
      // Trigger the print dialog
      window.print();
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filtered-table {
  width: 100%;
  max-width: 800px;
  border-collapse: collapse;
  margin-top: 20px;
}

.filtered-table th, .filtered-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.filtered-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Prevent wrapping in Name and Billing Name columns */
.nowrap {
  white-space: nowrap;
}

/* Hide buttons when printing */
@media print {
  .button-group {
    display: none;
  }

  #app {
    font-size: 0.8rem;
  }
}
</style>
